import React, { useEffect, useState, useRef } from "react";
import { Layout, Spin, Card, Tabs,Row,Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useGlobalContext } from "../../lib/storage";
import NavBar from "../navBar";
import SideMenu from "../sideMenu";
import NewLogo from "../../assets/images/NewLogoCW.svg"
import ErrorBoundary from "../errorBoundary";
import "antd/dist/antd.css";
import "./index.css"
import { Footer } from "antd/lib/layout/layout";

const { Header, Content } = Layout;
const { TabPane } = Tabs;

const AdminLayout = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { userPreferences, windowTabs, userData } = globalStore;
  const Themes = userData.CW360_V2_UI;
  const [loading, setLoading] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [menuToggle, setMenuToggle] = useState(false);
  const [fullMenuToggle, setFullMenuToggle] = useState(true);
  const [showToggler,setShowToggler] = useState(false)
  const [drawerFlag, setDrawerFlag] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, []);

  const [activeKey, setActiveKey] = useState(0);
  const onChangeKey = (key) => {
    history.push(windowTabs[key].url);
    setActiveKey(key);
  };

  const initialExc = useRef(false);
  useEffect(() => {
    if (initialExc.current) {
      if (userPreferences.enableMultiTab === "Y" && windowTabs.length > 0) {
        if (windowTabs[parseInt(activeKey)].content === null) {
          const localwindowTabs = [...windowTabs];
          localwindowTabs[parseInt(activeKey)].content = props.children;
          setGlobalStore({ windowTabs: [...localwindowTabs] });
        }
      }
    }
  }, [activeKey]);

  const editTab = (tabKey, action) => {
    if (action === "remove") {
      removeTab(tabKey);
    }
  };

  const responsiveFooterimage={
    xxl: 2,
    xl: 2,
    lg: 2,
    xs: 0,
    sm: 0,
    md: 0,
  }

  const responsiveSpace={
    xxl:9,
    xl:9,
    lg:9,
    xs: 0,
    sm: 0,
    md: 0,
  }

  const responsiveFooterText={
    xxl:13,
    xl:13,
    lg:13,
    xs: 0,
    sm: 0,
    md: 0,
  }

  const removeTab = (key) => {
    const localwindowTabs = [...windowTabs];
    const tabKey = parseInt(key);
    localwindowTabs.splice(tabKey, 1);
    setGlobalStore({ windowTabs: [...localwindowTabs] });
    let newKey = 0;
    if (tabKey >= 1) {
      newKey = tabKey - 1;
    }
    setActiveKey(`${newKey}`);
    history.push(windowTabs[newKey].url);
  };

  useEffect(() => {
    if (userPreferences.enableMultiTab === "Y") {
      if (windowTabs.length > 0) {
        const localwindowTabs = [...windowTabs];
        const tabIdx = localwindowTabs.findIndex((tab) => tab.url === history.location.pathname && tab.content === null);
        if (tabIdx >= 0) {
          localwindowTabs[tabIdx].content = props.children;
          setGlobalStore({ windowTabs: [...localwindowTabs] });
          setActiveKey(`${tabIdx}`);
          const localStorageTabs = [];
          localwindowTabs.map((tab) => {
            const newTabData = { ...tab, content: null };
            return localStorageTabs.push(newTabData);
          });
          localStorage.setItem("windowTabs", JSON.stringify(localStorageTabs));
        }
      } else {
        const newWindowTab = [];
        newWindowTab.push({
          url: history.location.pathname,
          title: "Window",
          content: props.children,
        });
        setGlobalStore({ windowTabs: [...newWindowTab] });
        setActiveKey(`0`);
        newWindowTab[0].content = null;
        localStorage.setItem("windowTabs", JSON.stringify(newWindowTab));
      }
      initialExc.current = true;
    }
  }, [windowTabs]);

  const onClose = () => {
    setDrawerFlag(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const innerWidth = windowSize.innerWidth

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={Themes} spin />} spinning={loading}>
      <Layout style={{ display: loading ? "none" : "block", fontFamily: "'Open Sans', sans-serif"}}>
        <Header  style={{backgroundColor:'#FFFFFF',padding:0,height:innerWidth>1004?'130px':'88px'}}>
          <NavBar showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle}  menuToggle={menuToggle} setDrawerFlag={setDrawerFlag} drawerFlag={drawerFlag} setMenuToggle={setMenuToggle}/>
        </Header>
        <Layout>
        {innerWidth>1004?
          <SideMenu showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle} setMenuToggle={setMenuToggle} menuToggle={menuToggle} drawerFlag={drawerFlag} onClose={onClose} />
        :null}
          <Content className="mainLayoutPadding">
              <Card style={{padding:'0px'}} bodyStyle={{padding:"15px"}}>
              <div style={{backgroundColor:'white',borderRadius:'6px 6px 0px 0px'}}>
                {userPreferences.enableMultiTab === "Y" ? (
                  <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
                    {windowTabs.map((tab, index) => (
                      <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                        <ErrorBoundary>{tab.content}</ErrorBoundary>
                      </TabPane>
                    ))}
                  </Tabs>
                ) : (
                  <ErrorBoundary>{props.children}</ErrorBoundary>
                )}
                </div>
              </Card>
            
          </Content>
        </Layout>
        {/* <Layout>
          <Footer style={{height:'109px',backgroundColor:'#E1E3E7'}}>
            <Row gutter={2}>
              <Col {...responsiveSpace}/>
              <Col {...responsiveFooterimage}>
            <img style={{height:'17px',marginTop:'20px',marginRight:'15px'}} src={NewLogo} alt=''/>
            </Col>
            <Col {...responsiveFooterText}>
            <p className="font-footer" style={{marginTop:'21px',lineHeight:'16px',opacity:1,fontSize:'12px'}}>Copyright 2020 CW Suite | All Rights Reserved</p>
            </Col>
            </Row>
          </Footer>
        </Layout> */}
      </Layout>
    </Spin>
  );
};

export default AdminLayout;
